
@media only screen
and (min-device-width : 320px)
and (max-device-width : 812px)
and (orientation : portrait) {

}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 736px)
and (orientation : landscape) {

}

@media only screen
and (min-device-width : 813px)
and (max-device-width : 1024px)
and (orientation : landscape) {
    .MenuColumn {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        columns: 2;
    }
    .MenuItem {
        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid;           /* Theoretically FF 20+ */
        break-inside: avoid-column;         /* IE 11 */
        display:table;                      /* Actually FF 20+ */
    }
}

@media only screen
and (min-device-width : 813px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    .MenuColumn {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        columns: 2;
    }
    .MenuItem {
        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid;           /* Theoretically FF 20+ */
        break-inside: avoid-column;         /* IE 11 */
        display:table;                      /* Actually FF 20+ */
    }
}

@media only screen
and (min-device-width : 1024px)
and (orientation : landscape) {
    .MenuColumn {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        columns: 3;
    }
    .MenuItem {
        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid;           /* Theoretically FF 20+ */
        break-inside: avoid-column;         /* IE 11 */
        display:table;                      /* Actually FF 20+ */
    }
}
